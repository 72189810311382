/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import ArrowsPhone from "../ArrowsPhone";
import Redirect from "../Redirect";
import LeftArrow from "./LeftArrow";
import "./Page.scss";
import Input from "./PageTypes/Input/Input";
import Picklist from "./PageTypes/Picklist/Picklist";
import RightArrow from "./RightArrow";
import StartSimulation from "./utils/StartSimulation";

export default function Page(props) {
  let pageContent = null;
  let startedSimulation = useSelector(
    (state) => state.backend.startedSimulation
  );

  switch (props.pageType) {
  case "picklist":
    pageContent = (
      <Picklist
        values={props.values}
        reduxAction={props.reduxAction}
        reduxStateName={props.reduxStateName}
        myNameDb={props.myNameDb}
      />
    );
    break;

  case "input":
    pageContent = (
      <Input
        placeholder={props.placeholder}
        type={props.type}
        reduxAction={props.reduxAction}
        reduxStateName={props.reduxStateName}
        icon={props.icon}
        checkData={props.checkData}
        dataType={props.dataType}
        myNameDb={props.myNameDb}
        optin={props.optin}
      />
    );
    break;
  default:
    break;
  }

  const professionalActivity = useSelector((state) => state.userInput.professionalActivity);

  if (startedSimulation === "false") {
    return <StartSimulation pageIndex={props.pageIndex} />;
  }
  
  return (
    <div className="page">
      <Redirect pageIndex={props.pageIndex} />
      {
        props.pageIndex === 5 && professionalActivity !== "Salarié(e)" ?
          <LeftArrow pageIndex={props.pageIndex} preciseIndex={4} totalPages={props.totalPages} />
          :
          <LeftArrow pageIndex={props.pageIndex} totalPages={props.totalPages} />
      }

      <div className="page-container">
        <h2 className="title">{props.title}</h2>
        <p className="subtitle">{props.subtitle}</p>
        {pageContent}
      </div>

      <RightArrow pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <ArrowsPhone pageIndex={props.pageIndex} />
    </div>
  );
}

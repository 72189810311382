/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateIncome } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));

  return numerousValue > 0 && numerousValue < 1000000;
}

export default function Income(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title={
          <>
            Quels sont vos revenus nets{" "}
            <span style={{ color: "#015de6" }}>PAR MOIS</span> avant impôts en CHF ?
          </>
        }
        subtitle="Pour identifier les meilleurs dispositifs"
        pageType="input"
        type="numerous"
        placeholder="Ex: 4 500 CHF"
        reduxAction={updateIncome}
        reduxStateName="income"
        checkData={checkData}
        dataType="money-integer"
        myNameDb="income"
        pageIndex={props.pageIndex}
        totalPages={props.totalPages}
      />
    </div>
  );
}

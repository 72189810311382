/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import { backendUrl } from "../../../Config/config";
import {
  putTempPageIndex,
  updateActualPageIndex,
  updateAge,
  updateCounty,
  updateEmail,
  updateFamilySituation,
  updateFirstName,
  updateIncome,
  updateLastName,
  updateNbrKids,
  updateOptin,
  updatePhone,
  updatePhoneChecked,
  updateProfessionalActivity,
  updateSessionId,
  updateStartedSimulation,
  updateTempPageIndex,
  updateTimestamp,
} from "../../../Store/store";
import Redirect from "../../Redirect";
import Modal from "./Modal";

function continueSimulation(dispatch) {
  dispatch(updateStartedSimulation("true"));
  dispatch(putTempPageIndex());
}

function startNewSimulation(dispatch) {
  fetch(backendUrl + "simulations/per_ibani/startSimulation/?newSimulation=true", {
    credentials: "include",
    crossDomain: true,
  }).then((resp) => {
    resp.json().then((data) => {
      if (data.statut === "success") {
        dispatch(
          updateSessionId(data.session_id === null ? "" : data.session_id)
        );
        dispatch(
          updateTimestamp(data.timestamp === null ? "" : data.timestamp)
        );
        dispatch(
          updateFamilySituation(
            data.family_situation === null ? "" : data.family_situation
          )
        );
        dispatch(updateAge(data.age === null ? "" : data.age));
        dispatch(updateNbrKids(data.nbr_kids === null ? "" : data.nbr_kids));
        dispatch(
          updateProfessionalActivity(
            data.professional_activity === null
              ? ""
              : data.professional_activity
          )
        );
        dispatch(updateIncome(data.income === null ? "" : data.income));
        dispatch(
          updateFirstName(data.first_name === null ? "" : data.first_name)
        );
        dispatch(updateLastName(data.last_name === null ? "" : data.last_name));
        dispatch(updateEmail(data.email === null ? "" : data.email));
        dispatch(updatePhone(data.phone === null ? "" : data.phone));
        dispatch(
          updatePhoneChecked(
            data.phone_checked === null ? "" : data.phone_checked
          )
        );
        dispatch(updateOptin(data.optin === null ? "" : data.optin));
        dispatch(updateCounty(data.county === null ? "" :  data.county));
        dispatch(updateStartedSimulation("true"));
        dispatch(updateActualPageIndex(parseInt(data.actual_page)));
      }
    });
  });
}

export default function StartSimulation(props) {
  const dispatch = useDispatch();

  fetch(backendUrl + "simulations/per_ibani/startSimulation/?newSimulation=false", {
    credentials: "include",
    crossDomain: true,
  }).then((resp) => {
    resp.json().then((data) => {
      if (data.statut === "success") {
        if (parseInt(data.actual_page) !== 1 || data.family_situation !== "") {
          dispatch(updateTempPageIndex(parseInt(data.actual_page)));
        }
        dispatch(
          updateSessionId(data.session_id === null ? "" : data.session_id)
        );
        dispatch(
          updateTimestamp(data.timestamp === null ? "" : data.timestamp)
        );
        dispatch(
          updateFamilySituation(
            data.family_situation === null ? "" : data.family_situation
          )
        );
        dispatch(updateAge(data.age === null ? "" : data.age));
        dispatch(updateNbrKids(data.nbr_kids === null ? "" : data.nbr_kids));
        dispatch(
          updateProfessionalActivity(
            data.professional_activity === null
              ? ""
              : data.professional_activity
          )
        );
        dispatch(updateIncome(data.income === null ? "" : data.income));
        dispatch(
          updateFirstName(data.first_name === null ? "" : data.first_name)
        );
        dispatch(updateLastName(data.last_name === null ? "" : data.last_name));
        dispatch(updateEmail(data.email === null ? "" : data.email));
        dispatch(updatePhone(data.phone === null ? "" : data.phone));
        dispatch(
          updatePhoneChecked(
            data.phone_checked === null ? "" : data.phone_checked
          )
        );
        dispatch(updateOptin(data.optin === null ? "" : data.optin));
        dispatch(updateCounty(data.county === null ? "" :  data.county));

        if (parseInt(data.actual_page) === 1 && data.family_situation === null) {
          dispatch(updateStartedSimulation("true"));
          dispatch(updateActualPageIndex(parseInt(data.actual_page)));
        }
      }
    });
  });

  return (
    <>
      <Redirect pageIndex={props.pageIndex} />
      <Modal
        continueSimulation={continueSimulation}
        startNewSimulation={startNewSimulation}
      />
    </>
  );
}

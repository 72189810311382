/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { nextPage, updateCounty } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));

  return numerousValue > 0 && numerousValue < 1000;
}

export default function Localisation(props) {
  const dispatch = useDispatch();
  const sessionId = useSelector((state) => state.backend.sessionId);
  const timestamp = useSelector((state) => state.backend.timestamp);
  const professionalActivity = useSelector(
    (state) => state.userInput.professionalActivity
  );

  useEffect(() => {
    if (professionalActivity !== "Salarié(e)") {
      const redirect = setTimeout(() => {
        dispatch(nextPage({ sessionId, timestamp }));
      }, 200);

      return () => clearTimeout(redirect);
    }
  });

  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Dans quel canton travaillez-vous ?"
        subtitle="Pour identifier les meilleurs dispositifs de votre canton"
        pageType="picklist"
        values={[
          "Vaud, Valais, Neuchâtel",
          "Bâle, Jura, Soleure, Berne",
          "Genève",
          "Fribourg",
          "Autre",
        ]}
        reduxAction={updateCounty}
        reduxStateName="county"
        checkData={checkData}
        myNameDb="county"
        pageIndex={props.pageIndex}
        totalPages={props.totalPages}
      />
    </div>
  );
}

import React, { useEffect } from "react";
import "./InformationLeaveIbani.scss";
import logoIbaniDark from "../../../Pictures/logo_ibani.svg";

export const InformationLeaveIbani = () => {
  const [display, setDisplay] = React.useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay("none");
    }, 3000);

    return () => clearTimeout(timer);
  });

  return (
    <div style={{display}} className="information-leave-ibani">
      <img src={logoIbaniDark} alt="logo ibani" />
      <p>
        Vous vous apprêtez à faire une simulation PER sur le site de notre
        partenaire Lexem !
      </p>
      <div className="loader">
        <div className="front"></div>
      </div>
    </div>
  );
};
